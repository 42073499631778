<style>

</style>
<template>
 <!--eslint-disable -->
    <div class="text-center">
    <v-dialog
      v-model="dialogedit"
      width="650"
    >
      <v-card>
        <v-card-title class="align-items-baseline">
                <div>
                    <p class="fstitulomodal mb-0 bold">{{$t('solicitudes.title_edit')}}</p>
                </div>
                <v-spacer></v-spacer>
                <a
                    icon
                    @click="dialogedit = false"
                >
                <v-icon>mdi-close</v-icon>
                </a>
        </v-card-title>

        <v-card-text>
            <v-row>
               <v-col cols="6">
                 <v-select
                    :items="items"
                    label="select"
                    outlined
                    dense
                  ></v-select>
               </v-col>
               <v-col
                  cols="6"
                  >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :label="$t('solicitudes.fecha_presunta')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="black"
                      @click="menu = false"
                    >
                      {{$t('solicitudes.cancelar')}}
                    </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="input"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
               <v-col cols="12">
                  <v-textarea
                    filled
                    auto-grow
                    rows="4"
                    row-height="30"
                  ></v-textarea>
               </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4 ma-2 c-btn c-btn--primary"
            color="indigo darken-3"
            @click="dialogedit = false"
          >
             {{ $t('ensayos.guardar') }}
          </v-btn>
          <v-btn
            color="black"
            class="ma-2 white--text"
            @click="dialogedit = false"
          >
            {{ $t('ensayos.cancelar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
export default {
    computed: {
    },
    data: () => ({
      dialogedit: false,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      values: [],
      value: null,
    }),
  methods: {
    openmodal() {
      this.dialogedit = true;
    },

  },

};
</script>
